import React from "react"

export default function Die(props) {
    const styles = {
        backgroundColor: props.isHeld ? "#59E391" : "white"
    }
    return (
        <div 
            className="die-face" 
            style={styles}
            onClick={props.holdDice}
        >
           
              {/* <h2><img src="https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/1.png" /></h2> */}
            <h2 className="die-num">
            {/* {console.log(`https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/${props.value}.png`)} */}
            
            <img src={(`https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/${props.value}.png`)} ></img>
           

            </h2>
        </div>
    )
}